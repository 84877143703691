import React from "react"
import Layout from "./../components/layouts/Default"
import Seo from "./../components/layouts/Seo"
import Header from "./../components/common/Header"
import { graphql, useStaticQuery, Link } from "gatsby"
import Card from "./../components/common/Card"
import Reviews from "./../components/Reviews"

export default function Page() {
  const data = useStaticQuery(graphql`
    query Index {
      site {
        siteMetadata {
          siteUrl
        }
      }
      allSitePage {
        edges {
          node {
            path
          }
        }
      }

      header: file(
        relativePath: { eq: "headers/dierda_startseite_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bu: file(
        relativePath: {
          eq: "headers/dierda_berufsunfaehigkeitsversicherung_header.jpg"
        }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      alter: file(
        relativePath: { eq: "headers/dierda_altersvorsorge_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kredit: file(
        relativePath: { eq: "headers/dierda_privatkredit_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      baufi: file(
        relativePath: { eq: "headers/dierda_baufinanzierung_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      geldanlage: file(
        relativePath: { eq: "headers/dierda_geldanlage-allgemein_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="Dein Ansprechpartner in Sachen Finanzen"
        description="Wir von Florian Dierda Finanzdienstleistungen stellen Dich an
          vorderste Stelle. Wir beraten Dich unabhänig und neutral zu allen
          Deinen Finanzfragen."
        path="/"
      />
      <Header
        link="/anfragen/"
        linktext="Jetzt beraten lassen"
        link2="/ueber-uns/"
        link2text="Über uns"
        image={data.header.childImageSharp.fluid}
      >
        <h1 className="mt-1 text-2xl leading-relaxed sm:text-3xl lg:text-4xl font-extrabold text-gray-900  mb-8">
          Dein aufklärender und kompetenter Ansprechpartner in Sachen{" "}
          <span className="text-blue-500">Finanzen</span>
        </h1>
        <p className="text-xl leading-7 text-indigo-500 mb-6 italic ">
          &bdquo;Finanzplanung - von Menschen für Menschen&ldquo;
        </p>
        <p className="text-lg leading-7 text-gray-700 ">
          Wir von Florian Dierda Finanzdienstleistungen stellen Dich an
          vorderste Stelle. Alle Deine Finanzfragen beantworten wir unabhänig
          und neutral und unterstützen Dich bei folgenden Themen:{" "}
          <Link
            to="/versicherungen/#angebote"
            className="font-medium text-indigo-600 hover:underline"
          >
            Versicherungen
          </Link>
          ,{" "}
          <Link
            to="/finanzierungen/#angebote"
            className="font-medium text-indigo-600 hover:underline"
          >
            Finanzierungen
          </Link>{" "}
          und{" "}
          <Link
            to="/geldanlage/#angebote"
            className="font-medium text-indigo-600 hover:underline"
          >
            Geldanlage
          </Link>
          .
        </p>
      </Header>
      <div className="container mx-auto my-6">
        <div className="relative  pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3" />
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                Unsere Finanzdienstleistungen
              </h2>
              <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-700 sm:mt-4">
                Wir können Dich in vielen Bereichen unterstützen. Nachfolgend
                findest Du eine Auswahl unserer Leistungen.
              </p>
            </div>
            <div className="mt-12 grid gap-5 mx-auto md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">
              <Card
                image={data.bu.childImageSharp.fluid}
                title="Berufs­unfähigkeits­versicherung"
                link="/versicherungen/berufsunfaehigkeitsversicherung/"
                subtitle="Versicherungen"
                sublink="/versicherungen/"
                text="Die Dienst- und Berufs&shy;unfähigkeits&shy;versicherung als individueller Schutz für jeden Erwerbstätigen."
              />

              <Card
                image={data.alter.childImageSharp.fluid}
                title="Altersvorsorge"
                link="/versicherungen/altersvorsorge/"
                subtitle="Versicherungen"
                sublink="/versicherungen/"
                text="Deine sichere Altersvorsorge: Genieße den Ruhestand in der Sonne."
              />
              <Card
                image={data.kredit.childImageSharp.fluid}
                title="Privatkredit"
                link="/finanzierungen/privatkredit/"
                subtitle="Finanzierungen"
                sublink="/finanzierungen/"
                text="Wir finden Deinen günstigsten Privatkredit."
              />
              <Card
                image={data.baufi.childImageSharp.fluid}
                title="Baufinanzierung"
                link="/finanzierungen/baufinanzierung/"
                subtitle="Finanzierungen"
                sublink="/finanzierungen/"
                text="Niedrige Zinsen bei der Immobilienfinanzierung: Wir vergleichen und beraten Dich unabhängig."
              />

              <Card
                image={data.geldanlage.childImageSharp.fluid}
                title="Allgemeine Geldanlage"
                link="/geldanlage/allgemeine-geldanlage/"
                subtitle="Geldanlage"
                sublink="/geldanlage/"
                text="Geldanlage: Wie Du Dein Geld langfristig vermehrst."
              />
            </div>
          </div>
        </div>

        <Reviews />
      </div>
    </Layout>
  )
}
